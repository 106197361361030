'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var tslib_es6 = require('./node_modules/tslib/tslib.es6.mjs.js');
var appCache = require('./appCache.js');

var _a;
exports.ZomentumRoutes = void 0;
(function (ZomentumRoutes) {
  ZomentumRoutes["AccountSettings"] = "/settings/account";
  ZomentumRoutes["Assessments"] = "/assessments";
  ZomentumRoutes["Authorize"] = "/authorize";
  ZomentumRoutes["AutoTaskIntegration"] = "/settings/integrations/autotask-integration";
  ZomentumRoutes["BulkImportOpportunities"] = "/import/opportunity";
  ZomentumRoutes["BulkImportClients"] = "/import/client_company";
  ZomentumRoutes["BulkImportProducts"] = "/import/items";
  ZomentumRoutes["CancelSubscription"] = "/settings/cancel";
  ZomentumRoutes["ClientsDetail"] = "/clients/:id";
  ZomentumRoutes["ClientsList"] = "/clients";
  ZomentumRoutes["ConnectWiseIntegration"] = "/settings/integrations/connectwise-integration";
  ZomentumRoutes["CreateReport"] = "/create-report";
  ZomentumRoutes["CustomFieldsList"] = "/settings/custom-fields/:entity";
  ZomentumRoutes["CustomFieldsSettings"] = "/settings/custom-fields";
  ZomentumRoutes["DocumentsDetail"] = "/documents/:id";
  ZomentumRoutes["DocumentsList"] = "/documents";
  ZomentumRoutes["DocumentsPdfView"] = "/documents/view/pdf/:id";
  ZomentumRoutes["DocumentsPreview"] = "/documents/view/:id";
  ZomentumRoutes["DocumentSuccessSignature"] = "/success/signature";
  ZomentumRoutes["EmailSettings"] = "/settings/email-settings";
  ZomentumRoutes["EmailTemplateSettings"] = "/settings/email-templates";
  ZomentumRoutes["Error"] = "/error";
  ZomentumRoutes["NotFound"] = "/not-found";
  ZomentumRoutes["IntegrationSettings"] = "/settings/integrations";
  ZomentumRoutes["InfusionSoftIntegration"] = "/settings/integrations/infusionsoft-integration";
  ZomentumRoutes["HubSpotIntegration"] = "/settings/integrations/hubspot-integration";
  ZomentumRoutes["InvoicesList"] = "/settings/invoices";
  ZomentumRoutes["KaseyaIntegration"] = "/settings/integrations/kaseya-integration";
  ZomentumRoutes["LoginOrSignup"] = "/welcome";
  ZomentumRoutes["Login"] = "/login";
  ZomentumRoutes["Ms365AssessmentSuccess"] = "/assessments/ms365/success";
  ZomentumRoutes["Notifications"] = "/settings/notifications";
  ZomentumRoutes["OpportunityDetail"] = "/opportunities/:id";
  ZomentumRoutes["OpportunityList"] = "/opportunities";
  ZomentumRoutes["PipelineSettings"] = "/settings/pipeline";
  ZomentumRoutes["Preferences"] = "/settings/preferences";
  ZomentumRoutes["ProductsDetail"] = "/products/:id";
  ZomentumRoutes["ProductsList"] = "/products";
  ZomentumRoutes["RepairShoprIntegrationPage"] = "/settings/integrations/repairshopr-integration";
  ZomentumRoutes["ReportsDetail"] = "/reports/:id";
  ZomentumRoutes["ReportsList"] = "/reports";
  ZomentumRoutes["SalesActivityAutomationCreate"] = "/sales-automation/create";
  ZomentumRoutes["SalesActivityAutomationsEdit"] = "/sales-automation/edit/:id";
  ZomentumRoutes["SalesActivityAutomationsClone"] = "/sales-automation/clone/:id";
  ZomentumRoutes["SalesActivityAutomationsList"] = "/sales-automation";
  ZomentumRoutes["Settings"] = "/settings";
  ZomentumRoutes["SignatureSuccessIframe"] = "/signature-callback";
  ZomentumRoutes["SyncroIntegration"] = "/settings/integrations/syncro-integration";
  ZomentumRoutes["Suppliers"] = "/settings/suppliers";
  ZomentumRoutes["TaxRegionSettings"] = "/settings/tax-regions";
  ZomentumRoutes["TemplatesDetail"] = "/templates/:id";
  ZomentumRoutes["TemplatesList"] = "/templates";
  ZomentumRoutes["TimezoneSettings"] = "/settings/account/:feature";
  ZomentumRoutes["Unauthorized"] = "/unauthorized";
  ZomentumRoutes["UserSettings"] = "/settings/users";
  ZomentumRoutes["VerifyEmail"] = "/verify-email";
  ZomentumRoutes["SetupAccount"] = "/setup-account";
  ZomentumRoutes["VerifyPayment"] = "/payment/:id";
  ZomentumRoutes["SalesActivityPage"] = "/sales-activity";
  ZomentumRoutes["OnBoardingScheduledPage"] = "/onboarding";
  ZomentumRoutes["AuditLogs"] = "/audit-logs";
  ZomentumRoutes["DocumentApproval"] = "/settings/document-approval";
  ZomentumRoutes["DocumentApprovalDetailsPage"] = "/settings/document-approval/:id";
  ZomentumRoutes["DocumentApprovalCreatePage"] = "/settings/document-approval/create";
  ZomentumRoutes["BulkActionResultPage"] = "/async/:id";
  ZomentumRoutes["Billing"] = "/settings/billing";
  ZomentumRoutes["PurchaseOrder"] = "/purchaseorders";
  ZomentumRoutes["PurchaseOrderAddPage"] = "/purchaseorders/add";
  ZomentumRoutes["PurchaseOrderSubmitPage"] = "/purchaseorder/submit/:id";
  ZomentumRoutes["PurchaseOrderDetailPage"] = "/purchaseorder/:id";
  ZomentumRoutes["PricingPlanErrorPage"] = "/pricingplanerror";
  ZomentumRoutes["Marketplace"] = "/align";
  ZomentumRoutes["MarketplaceCollection"] = "/align/collections/:collectionRoute";
  ZomentumRoutes["MarketplaceResourcePage"] = "/align/vendors/:id/resources";
  ZomentumRoutes["MarketplaceResourcePageDirectory"] = "/align/vendors/:id/resources/:directoryId";
  ZomentumRoutes["MarketplaceProductDetail"] = "/align/products/:id";
  ZomentumRoutes["MarketplaceSettingsPage"] = "/settings/align";
  ZomentumRoutes["MarketplaceMyVendors"] = "/align/vendors";
  ZomentumRoutes["MarketplaceVendorDetail"] = "/align/vendors/:id";
  ZomentumRoutes["QuickbooksIntegration"] = "/settings/integrations/quickbooks-integration";
  ZomentumRoutes["ZapierIntegrationPage"] = "/settings/integrations/zapier";
  ZomentumRoutes["GoogleDriveIntegrationPage"] = "/settings/integrations/google-cloud";
  ZomentumRoutes["MarketplaceSubscriptions"] = "/align/subscriptions";
  ZomentumRoutes["MarketplaceMyVendorsOld"] = "/marketplace/my-vendors";
  ZomentumRoutes["HaloPSAIntegrationPage"] = "/settings/integrations/halopsa-integration";
  ZomentumRoutes["MarketplaceCommissions"] = "/align/commissions";
  ZomentumRoutes["PartnerDashboard"] = "/partner/dashboard";
  // Vendor Routes
  ZomentumRoutes["VendorPartnersDetail"] = "/partners/:id";
  ZomentumRoutes["VendorOpportunityList"] = "/opportunities";
  ZomentumRoutes["VendorPartnerList"] = "/partners";
  ZomentumRoutes["VendorSettings"] = "/settings";
  ZomentumRoutes["VendorResources"] = "/resources";
  ZomentumRoutes["VendorResourcesFolder"] = "/resources/:id";
  ZomentumRoutes["VendorDashboard"] = "/dashboard";
  ZomentumRoutes["VendorMarketplace"] = "/align";
  ZomentumRoutes["VendorCompanyDetails"] = "/settings/company-details";
  ZomentumRoutes["VendorCustomFieldsList"] = "/settings/custom-fields/:entity";
  ZomentumRoutes["VendorCustomFieldsSettings"] = "/settings/custom-fields";
  ZomentumRoutes["VendorMarketplaceListings"] = "/listings";
  ZomentumRoutes["VendorMarketplaceProductListings"] = "/settings/listings";
  ZomentumRoutes["VendorMarketplaceAddProduct"] = "/settings/listings/add";
  ZomentumRoutes["VendorMarketplaceEditProduct"] = "/settings/listings/:id";
  ZomentumRoutes["VendorMarketplaceSKUs"] = "/products";
  ZomentumRoutes["VendorOpportunityDetail"] = "/opportunities/:id";
  ZomentumRoutes["VendorTemplates"] = "/templates";
  ZomentumRoutes["VendorTemplateDetail"] = "/templates/:id";
  ZomentumRoutes["VendorMarketplaceSKUDetails"] = "/products/:id";
  ZomentumRoutes["VendorAccountSettings"] = "/settings/account";
  ZomentumRoutes["VendorPipelineSettings"] = "/settings/pipeline";
  ZomentumRoutes["VendorUsers"] = "/settings/users";
  ZomentumRoutes["VendorPartnerSettings"] = "/settings/partner";
  ZomentumRoutes["VendorPartnersBulkImport"] = "/import/partners";
  ZomentumRoutes["VendorClientBulkImport"] = "/import/vendor_client";
  ZomentumRoutes["VendorOpportunitiesBulkImport"] = "/import/opportunity";
  ZomentumRoutes["VendorProductsBulkImport"] = "/import/products";
  ZomentumRoutes["VendorClientList"] = "/clients";
  ZomentumRoutes["VendorClientDetail"] = "/clients/:id";
  ZomentumRoutes["VendorEmailTemplateSettings"] = "/settings/email-templates";
  ZomentumRoutes["CustomDistributorIntegrationAdd"] = "/settings/integrations/custom-distributor/add";
  ZomentumRoutes["CustomDistributorIntegrationDetail"] = "/settings/integrations/custom-distributor/:id";
  ZomentumRoutes["VendorPartnerTiering"] = "/settings/tiering";
  ZomentumRoutes["UnsubscribeEmails"] = "/unsubscribe-emails";
  ZomentumRoutes["FinancePage"] = "/finance";
})(exports.ZomentumRoutes || (exports.ZomentumRoutes = {}));
var HttpErrorResponse = /** @class */function () {
  function HttpErrorResponse() {
    this.code = 0;
    this.description = "";
    this.errors = {};
    this.should_display_description_string = false;
    this.subtype = "";
  }
  return HttpErrorResponse;
}();
var PasswordRegex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
var EmailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
var SubdomainRegex = /^[a-zA-Z0-9][a-zA-Z0-9.-]+[a-zA-Z0-9]$/;
var companyFormSubdomainRegex = /^[A-Za-z0-9](?:[A-Za-z0-9\-]{0,61}[A-Za-z0-9])$/;
var PhoneRegex = /^[\d()+-]{0,20}$/;
var AlphabetsOnlyRegex = /^[a-zA-Z]+$/;
var AlphaNumericWithDashesRegex = /^[A-Za-z0-9_-]*$/;
var DefaultTimestampFormat = "MMM D, YYYY";
var DefaultTimestampFormatWithTime = "MMM D, YYYY hh:mm:ss A";
var FullDateFormat = "MMMM Do YYYY";
var TimeFormat = "hh:mm A";
var URLRegex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[-a-zA-Z]{2,10}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
// NOTE ON CREDIT CARD REGEX
// ^(?:4[0-9]{12}(?:[0-9]{3})?          # Visa
//  |  (?:5[1-5][0-9]{2}                # MasterCard
//      | 222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}
//  |  3[47][0-9]{13}                   # American Express
//  |  3(?:0[0-5]|[68][0-9])[0-9]{11}   # Diners Club
//  |  6(?:011|5[0-9]{2})[0-9]{12}      # Discover
//  |  (?:2131|1800|35\d{3})\d{11}      # JCB
// )$
var CreditCardRegex = "^(?:4[0-9]{12}(?:[0-9]{3})?|(?:5[1-5][0-9]{2}| 222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|6(?:011|5[0-9]{2})[0-9]{12}|(?:2131|1800|35d{3})d{11})$";
exports.Currency = void 0;
(function (Currency) {
  Currency["DKK"] = "DKK";
  Currency["USD"] = "USD";
  Currency["SEK"] = "SEK";
  Currency["CAD"] = "CAD";
  Currency["AUD"] = "AUD";
  Currency["NZD"] = "NZD";
  Currency["EUR"] = "EUR";
  Currency["GBP"] = "GBP";
  Currency["INR"] = "INR";
  Currency["ZAR"] = "ZAR";
  Currency["ALL"] = "ALL";
  Currency["AMD"] = "AMD";
  Currency["AZN"] = "AZN";
  Currency["BYN"] = "BYN";
  Currency["BAM"] = "BAM";
  Currency["BGN"] = "BGN";
  Currency["HRK"] = "HRK";
  Currency["CZK"] = "CZK";
  Currency["GEL"] = "GEL";
  Currency["HUF"] = "HUF";
  Currency["ISK"] = "ISK";
  Currency["MDL"] = "MDL";
  Currency["NOK"] = "NOK";
  Currency["PLN"] = "PLN";
  Currency["RON"] = "RON";
  Currency["RUB"] = "RUB";
  Currency["MKD"] = "MKD";
  Currency["RSD"] = "RSD";
  Currency["CHF"] = "CHF";
  Currency["TRY"] = "TRY";
  Currency["UAH"] = "UAH";
  Currency["SGD"] = "SGD";
  Currency["HKD"] = "HKD";
  Currency["AED"] = "AED";
  Currency["BRL"] = "BRL";
  Currency["BHD"] = "BHD";
  Currency["FJD"] = "FJD";
  Currency["NIS"] = "NIS";
})(exports.Currency || (exports.Currency = {}));
var CurrencyOptions = [{
  symbol: exports.Currency.USD,
  label: "US Dollar (USD)"
}, {
  symbol: exports.Currency.DKK,
  label: "Danish Krone (DKK)"
}, {
  symbol: exports.Currency.SEK,
  label: "Swedish Krona (SEK)"
}, {
  symbol: exports.Currency.CAD,
  label: "Canadian Dollar (CAD)"
}, {
  symbol: exports.Currency.AUD,
  label: "Australian Dollar (AUD)"
}, {
  symbol: exports.Currency.NZD,
  label: "New Zealand Dollar (NZD)"
}, {
  symbol: exports.Currency.EUR,
  label: "Euro (EUR)"
}, {
  symbol: exports.Currency.GBP,
  label: "Pound Sterling (GBP)"
}, {
  symbol: exports.Currency.INR,
  label: "Indian Rupee (INR)"
}, {
  symbol: exports.Currency.ZAR,
  label: "Rand (ZAR)"
}, {
  symbol: exports.Currency.ALL,
  label: "Albanian LEK (ALL)"
}, {
  symbol: exports.Currency.AMD,
  label: "Armenian Dram (AMD)"
}, {
  symbol: exports.Currency.AZN,
  label: "Azerbaijani manat (AZN)"
}, {
  symbol: exports.Currency.BYN,
  label: "Belarusian ruble (BYN)"
}, {
  symbol: exports.Currency.BAM,
  label: "Bosnia and Herzegovina convertible mark (BAM)"
}, {
  symbol: exports.Currency.BGN,
  label: "Bulgarian lev (BGN)"
}, {
  symbol: exports.Currency.HRK,
  label: "Croatian kuna (HRK)"
}, {
  symbol: exports.Currency.CZK,
  label: "Czech koruna (CZK)"
}, {
  symbol: exports.Currency.GEL,
  label: "Georgian lari (GEL, ₾)"
}, {
  symbol: exports.Currency.HUF,
  label: "Hungarian forint (HUF, Ft)"
}, {
  symbol: exports.Currency.ISK,
  label: "Icelandic króna: second króna (ISK, kr, Íkr)"
}, {
  symbol: exports.Currency.MDL,
  label: "Moldovan leu (MDL, L)"
}, {
  symbol: exports.Currency.NOK,
  label: "Norwegian krone (NOK, kr, –)"
}, {
  symbol: exports.Currency.PLN,
  label: "Polish złoty (PLN, zł)"
}, {
  symbol: exports.Currency.RON,
  label: "Romanian leu (RON, lei)"
}, {
  symbol: exports.Currency.RUB,
  label: "Russian ruble (RUB, ₽)"
}, {
  symbol: exports.Currency.MKD,
  label: "Second Macedonian denar (MKD, ден)"
}, {
  symbol: exports.Currency.RSD,
  label: "Serbian dinar (RSD, RSD or РСД (unofficial: din. or дин.))"
}, {
  symbol: exports.Currency.CHF,
  label: "Swiss franc (CHF, CHF)"
}, {
  symbol: exports.Currency.TRY,
  label: "Turkish lira (TRY, ₺)"
}, {
  symbol: exports.Currency.UAH,
  label: "Ukrainian hryvnia (UAH, ₴)"
}, {
  symbol: exports.Currency.SGD,
  label: "Singapore Dollar (SGD)"
}, {
  symbol: exports.Currency.HKD,
  label: "Hong kong Dollar (HKD)"
}, {
  symbol: exports.Currency.AED,
  label: "United Arab Emirates Dirham (AED)"
}, {
  symbol: exports.Currency.BRL,
  label: "Brazilian Real (BRL, R$)"
}, {
  symbol: exports.Currency.BHD,
  label: "Bahraini Dinars (BHD)"
}, {
  symbol: exports.Currency.FJD,
  label: "Fijian Dollar (FJD)"
}, {
  symbol: exports.Currency.NIS,
  label: "New Israeli Shekel (NIS)"
}];
var DefaultErrorMessage = "Something went wrong";
var DefaultPaginationPageSize = 50;
var Name = /** @class */function () {
  function Name() {
    this.first = "";
    this.full = "";
    this.last = "";
  }
  Name.getInitials = function (name) {
    return (name.full || name.first).split(" ").filter(function (a) {
      return !!a && !!a.length;
    }).map(function (a) {
      return a[0].toUpperCase();
    }).join(" ");
  };
  return Name;
}();
var Address = /** @class */function () {
  function Address(address_line_1, address_line_2, city, state, pincode, country, phone, is_default) {
    if (address_line_1 === void 0) {
      address_line_1 = "";
    }
    if (address_line_2 === void 0) {
      address_line_2 = "";
    }
    if (city === void 0) {
      city = "";
    }
    if (state === void 0) {
      state = "";
    }
    if (pincode === void 0) {
      pincode = "";
    }
    if (country === void 0) {
      country = "";
    }
    if (phone === void 0) {
      phone = "";
    }
    if (is_default === void 0) {
      is_default = false;
    }
    this.id = null;
    this.address_line_1 = "";
    this.address_line_2 = "";
    this.city = "";
    this.country = "";
    this.phone = "";
    this.pincode = "";
    this.state = "";
    this.is_default = false;
    this.address_line_1 = address_line_1;
    this.address_line_2 = address_line_2;
    this.city = city;
    this.country = country;
    this.phone = phone;
    this.pincode = pincode;
    this.state = state;
    this.is_default = is_default;
  }
  return Address;
}();
var PurchaseOrderAddress = /** @class */function () {
  function PurchaseOrderAddress() {
    this.id = null;
    this.address_line_1 = null;
    this.address_line_2 = null;
    this.city = null;
    this.country = null;
    this.phone = null;
    this.pincode = null;
    this.state = null;
    this.is_default = false;
  }
  return PurchaseOrderAddress;
}();
var Social = /** @class */function () {
  function Social() {
    this.facebook = "";
    this.linkedin = "";
    this.reddit = "";
    this.twitter = "";
    this.website = "";
  }
  return Social;
}();
var LinkHref = /** @class */function () {
  function LinkHref() {
    this.href = "";
  }
  return LinkHref;
}();
var Links = /** @class */function () {
  function Links() {
    this.next = new LinkHref();
    this.self = new LinkHref();
  }
  return Links;
}();
var DeletedRecord = /** @class */function () {
  function DeletedRecord() {
    this.deleted = false;
  }
  return DeletedRecord;
}();
var NotificationConfiguration = /** @class */function () {
  function NotificationConfiguration() {
    this.api_key = "";
    this.app_id = "";
    this.token = "";
  }
  return NotificationConfiguration;
}();
var NotificationSetting = /** @class */function () {
  function NotificationSetting() {
    this.reminder_unit = null;
    this.is_enabled = false;
    this.reminder_interval = 0;
  }
  return NotificationSetting;
}();
var NotificationSettings = /** @class */function () {
  function NotificationSettings() {
    this.sales_meeting_email_reminder = new NotificationSetting();
    this.sales_meeting_in_app_reminder = new NotificationSetting();
    this.sales_task_email_reminder = new NotificationSetting();
    this.sales_task_in_app_reminder = new NotificationSetting();
    this.digest_daily_email_notification = false;
    this.digest_weekly_email_notification = false;
    this.document_comment_email_notification = false;
    this.document_view_email_notification = false;
    this.document_signature_cancel_email_notification = false;
    this.document_in_draft_email_notification = false;
    this.document_sent_but_not_viewed_email_notification = false;
    this.document_viewed_but_not_approved_email_notification = false;
    this.tier_change_in_app_notification = false;
    this.resource_shared_in_app_notification = false;
  }
  return NotificationSettings;
}();
exports.ZomentumEntities = void 0;
(function (ZomentumEntities) {
  ZomentumEntities["Client"] = "client_company";
  ZomentumEntities["Products"] = "items";
  ZomentumEntities["Document"] = "document";
  ZomentumEntities["DocumentBlock"] = "block";
  ZomentumEntities["Comment"] = "comment";
  ZomentumEntities["Activities"] = "activities";
  ZomentumEntities["Opportunity"] = "opportunity";
  ZomentumEntities["Product"] = "item";
  ZomentumEntities["ClientContact"] = "client_user";
  ZomentumEntities["SalesTask"] = "task";
  ZomentumEntities["SalesMeeting"] = "meeting";
  ZomentumEntities["Email"] = "email";
  ZomentumEntities["EmailThread"] = "thread";
  ZomentumEntities["EmailMessage"] = "message";
  ZomentumEntities["SalesLogEmail"] = "log_email";
  ZomentumEntities["SalesLogCall"] = "log_call";
  ZomentumEntities["Assessment"] = "assessment";
  ZomentumEntities["Qbr"] = "qbr";
  ZomentumEntities["CustomField"] = "custom_field";
  ZomentumEntities["SalesActivity"] = "sales_activity";
  ZomentumEntities["Supplier"] = "supplier";
  ZomentumEntities["User"] = "user";
  ZomentumEntities["Pricing"] = "pricing";
  ZomentumEntities["VendorResource"] = "vendor_resource";
  ZomentumEntities["VendorPartner"] = "partner_metadata";
  ZomentumEntities["VendorCompany"] = "company";
  ZomentumEntities["MarketplaceListing"] = "marketplace_listing";
  ZomentumEntities["Vendors"] = "vendors";
  ZomentumEntities["VendorCompanies"] = "vendor_company";
  ZomentumEntities["VendorTemplate"] = "template";
  ZomentumEntities["VendorOpportunity"] = "vendor_opportunity";
  ZomentumEntities["MarketplaceSKU"] = "marketplace_sku";
  ZomentumEntities["VendorClient"] = "vendor_client_company";
  ZomentumEntities["VendorClientContact"] = "vendor_client_user";
  ZomentumEntities["SalesAutomation"] = "sales_automation_rule";
  ZomentumEntities["VendorUser"] = "vendor_user";
  ZomentumEntities["Transaction"] = "transaction";
  ZomentumEntities["RecurringCommissionRecords"] = "commission_record";
  ZomentumEntities["Invoice"] = "partner_align_invoice";
  ZomentumEntities["CommissionRule"] = "commission_automation_rule";
})(exports.ZomentumEntities || (exports.ZomentumEntities = {}));
var getZomentumEntityDisplayValue = function getZomentumEntityDisplayValue(zomentumEntity, isSingular) {
  if (!zomentumEntity || zomentumEntity.length === 0) {
    return "";
  }
  switch (zomentumEntity) {
    case exports.ZomentumEntities.Client:
      {
        return isSingular ? "Client" : "Clients";
      }
    case exports.ZomentumEntities.Products:
      {
        return isSingular ? "Product" : "Products";
      }
    case exports.ZomentumEntities.Document:
      {
        return isSingular ? "Document" : "Documents";
      }
    case exports.ZomentumEntities.DocumentBlock:
      {
        return isSingular ? "Block" : "Blocks";
      }
    case exports.ZomentumEntities.Comment:
      {
        return isSingular ? "Note" : "Notes";
      }
    case exports.ZomentumEntities.Activities:
      {
        return isSingular ? "Activity" : "Activities";
      }
    case exports.ZomentumEntities.Opportunity:
      {
        return isSingular ? "Opportunity" : "Opportunities";
      }
    case exports.ZomentumEntities.Product:
      {
        return isSingular ? "Product" : "Products";
      }
    case exports.ZomentumEntities.ClientContact:
      {
        return isSingular ? "Contact" : "Contacts";
      }
    case exports.ZomentumEntities.SalesTask:
      {
        return isSingular ? "Task" : "Tasks";
      }
    case exports.ZomentumEntities.SalesMeeting:
      {
        return isSingular ? "Meeting" : "Meetings";
      }
    case exports.ZomentumEntities.Email:
      {
        return isSingular ? "Email" : "Emails";
      }
    case exports.ZomentumEntities.EmailThread:
      {
        return isSingular ? "Thread" : "Threads";
      }
    case exports.ZomentumEntities.EmailMessage:
      {
        return isSingular ? "Email" : "Emails";
      }
    case exports.ZomentumEntities.SalesLogEmail:
      {
        return isSingular ? "Email Log" : "Email Logs";
      }
    case exports.ZomentumEntities.SalesLogCall:
      {
        return isSingular ? "Call Log" : "Call Logs";
      }
    case exports.ZomentumEntities.Assessment:
      {
        return isSingular ? "Assessment" : "Assessments";
      }
    case exports.ZomentumEntities.Pricing:
      {
        return isSingular ? "Pricing" : "Pricings";
      }
    case exports.ZomentumEntities.VendorPartner:
      {
        return isSingular ? "Partner" : "Partners";
      }
    case exports.ZomentumEntities.VendorClient:
      {
        return isSingular ? "Client" : "Clients";
      }
    case exports.ZomentumEntities.VendorOpportunity:
      {
        return isSingular ? "Opportunity" : "Opportunities";
      }
    case exports.ZomentumEntities.VendorClientContact:
      {
        return isSingular ? "Contact" : "Contacts";
      }
    case exports.ZomentumEntities.MarketplaceSKU:
      {
        return isSingular ? "Product" : "Products";
      }
    case exports.ZomentumEntities.Transaction:
      {
        return isSingular ? "Transaction" : "Transactions";
      }
    default:
      {
        return zomentumEntity.split("_").join(" ").replace(/\b\w/g, function (word) {
          return word.toUpperCase();
        });
      }
  }
};
var getRouteFromEntity = function getRouteFromEntity(entity) {
  switch (entity) {
    case exports.ZomentumEntities.Client:
      {
        return exports.ZomentumRoutes.ClientsDetail;
      }
    case exports.ZomentumEntities.Document:
      {
        return exports.ZomentumRoutes.DocumentsDetail;
      }
    case exports.ZomentumEntities.Opportunity:
      {
        return exports.ZomentumRoutes.OpportunityDetail;
      }
    case exports.ZomentumEntities.Product:
      {
        return exports.ZomentumRoutes.ProductsDetail;
      }
    default:
      {
        return "/";
      }
  }
};
var ZomentumLoginRoutes = [exports.ZomentumRoutes.LoginOrSignup, exports.ZomentumRoutes.Login, exports.ZomentumRoutes.VerifyEmail];
var isDashboardAllowedForPartner = function isDashboardAllowedForPartner() {
  var configurationSettings = appCache["default"].get(appCache.cacheConstant.configurationSettings, true);
  var isVendorAssociated = configurationSettings === null || configurationSettings === void 0 ? void 0 : configurationSettings.is_associated_with_vendors_other_than_default;
  return isVendorAssociated;
};
var getDefaultGrowPrivateRoute = function getDefaultGrowPrivateRoute(showPartnerAlignFeaturesFlag) {
  return showPartnerAlignFeaturesFlag && isDashboardAllowedForPartner() ? exports.ZomentumRoutes.PartnerDashboard : exports.ZomentumRoutes.OpportunityList;
};
var DefaultPartnerAlignPrivateRoute = exports.ZomentumRoutes.VendorDashboard;
var ZomentumEntityRequest = /** @class */function () {
  function ZomentumEntityRequest(entityType, entityId, accountId) {
    this.entityType = entityType;
    this.entityId = entityId;
    this.accountId = accountId;
  }
  return ZomentumEntityRequest;
}();
var ZomentumStorageKeys = {
  Token: "token",
  UserIp: "userip",
  Currency: "currency",
  Locale: "locale",
  DocumentType: "document-type",
  LastUsedEmail: "last-used-email",
  PendingPurchaseOrderReminder: "pending-purchase-order-reminder",
  UserCompany: appCache.cacheConstant.userCompany,
  ShouldUpdateOppStageOnDocManualUpdate: "should-update-opp-stage-on-doc-manual-update",
  MARKETPLACE_MYVENDORS_VIEW: "MARKETPLACE_MYVENDORS_VIEW",
  MARKETPLACE_HOME_VIEW: "MARKETPLACE_HOME_VIEW",
  INSIGHTS_TIME_RANGE: "INSIGHTS_TIME_RANGE",
  VENDOR_PROMOTION_DISMISSED: "VENDOR_PROMOTION_DISMISSED",
  GOF_BLOCK_CACHE: "GOF_BLOCK_CACHE",
  HUBSPOT_CHAT_TOKEN_LIST: "HUBSPOT_CHAT_TOKEN_LIST",
  SUBSCRIPTIONS: "SUBSCRIPTIONS",
  RETURN_TO_URL: "return_to_url",
  DASHBOARD_REVENUE_DATE_RANGE: "DASHBOARD_REVENUE_DATE_RANGE",
  PARTNER_RESOURCE_INSIGHTS: "PARTNER_RESOURCE_INSIGHTS",
  PARTNER_REVENUE_INSIGHTS: "PARTNER_REVENUE_INSIGHTS",
  APP_STATE: "APP_STATE",
  DASHBOARD_RESOURCE_DATE_FILTER: "DASHBOARD_RESOURCE_DATE_FILTER"
};
exports.TableQueryFilterOperands = void 0;
(function (TableQueryFilterOperands) {
  TableQueryFilterOperands["EqualTo"] = "=";
  TableQueryFilterOperands["MongoDbEqualTo"] = ":eq:";
  TableQueryFilterOperands["MongoDbMatches"] = ":in:";
  TableQueryFilterOperands["MongoDbLessThan"] = ":lt:";
  TableQueryFilterOperands["MongoDbLessThanOrEqualTo"] = ":lte:";
  TableQueryFilterOperands["MongoDbGreaterThan"] = ":gt:";
  TableQueryFilterOperands["MongoDbGreaterThanOrEqualTo"] = ":gte:";
  TableQueryFilterOperands["MongoDbRange"] = ":range:";
  TableQueryFilterOperands["NotInArray"] = ":non_empty_array:";
  TableQueryFilterOperands["MongoDbNotEqualTo"] = ":ne:";
  TableQueryFilterOperands["MongoDbNotIn"] = ":nin:";
  TableQueryFilterOperands["MongoDbNotNull"] = ":nnull:";
  TableQueryFilterOperands["MongoDbNull"] = ":null:";
})(exports.TableQueryFilterOperands || (exports.TableQueryFilterOperands = {}));
exports.TableTimestampFilterKeys = void 0;
(function (TableTimestampFilterKeys) {
  TableTimestampFilterKeys["Last24Hours"] = "Last 24 Hours";
  TableTimestampFilterKeys["Yesterday"] = "Yesterday";
  TableTimestampFilterKeys["Next24Hours"] = "Next 24 Hours";
  TableTimestampFilterKeys["Last7Days"] = "Last 7 Days";
  TableTimestampFilterKeys["Next7Days"] = "Next 7 Days";
  TableTimestampFilterKeys["Last30Days"] = "Last 30 Days";
  TableTimestampFilterKeys["Next30Days"] = "Next 30 Days";
  TableTimestampFilterKeys["AllTime"] = "All Time";
  TableTimestampFilterKeys["Custom"] = "Custom";
  TableTimestampFilterKeys["Today"] = "Today";
  TableTimestampFilterKeys["ThisWeek"] = "This Week";
})(exports.TableTimestampFilterKeys || (exports.TableTimestampFilterKeys = {}));
exports.ETableQueryFilterKeys = void 0;
(function (ETableQueryFilterKeys) {
  ETableQueryFilterKeys["AUTHOR_ID"] = "author_id";
  ETableQueryFilterKeys["OWNER_USER_ID"] = "owner_user_id";
  ETableQueryFilterKeys["ESTIMATED_CLOSE_DATE"] = "estimated_close_date";
  ETableQueryFilterKeys["DATE_TIME"] = "date_time";
  ETableQueryFilterKeys["COMMENT_ATTACHMENTS"] = "comment_attachments";
  ETableQueryFilterKeys["PRIORITY"] = "priority";
  ETableQueryFilterKeys["OUTCOME"] = "outcome";
  ETableQueryFilterKeys["INBOX_TYPE"] = "inbox_type";
})(exports.ETableQueryFilterKeys || (exports.ETableQueryFilterKeys = {}));
var DefaultTableFilterSortSearch = {
  currentFilters: new Array(),
  currentKeywords: null,
  currentPagination: {
    pageSize: DefaultPaginationPageSize,
    defaultPageSize: DefaultPaginationPageSize,
    hideOnSinglePage: true
  }
};
var TableQueryFilter = /** @class */function () {
  function TableQueryFilter(operand, filterKey, filterValue, filterDisplayText, filterEnable) {
    if (filterEnable === void 0) {
      filterEnable = false;
    }
    this.operand = operand;
    this.filterKey = filterKey;
    this.filterValue = filterValue;
    this.filterDisplayText = filterDisplayText || null;
    this.filterEnable = filterEnable;
  }
  return TableQueryFilter;
}();
var ZomentumEntity = /** @class */function () {
  function ZomentumEntity() {}
  return ZomentumEntity;
}();
var ZomentumSyncEntity = /** @class */function () {
  function ZomentumSyncEntity() {}
  return ZomentumSyncEntity;
}();
var ArchievedWithSyncModel = /** @class */function (_super) {
  tslib_es6.__extends(ArchievedWithSyncModel, _super);
  function ArchievedWithSyncModel() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  return ArchievedWithSyncModel;
}(ZomentumSyncEntity);
exports.ZomentumPageTitles = void 0;
(function (ZomentumPageTitles) {
  ZomentumPageTitles["Zomentum"] = "Zomentum";
})(exports.ZomentumPageTitles || (exports.ZomentumPageTitles = {}));
exports.FileUploadStatus = void 0;
(function (FileUploadStatus) {
  FileUploadStatus["DONE"] = "done";
  FileUploadStatus["REMOVED"] = "removed";
  FileUploadStatus["ERROR"] = "error";
})(exports.FileUploadStatus || (exports.FileUploadStatus = {}));
exports.ZomentumPSA = void 0;
(function (ZomentumPSA) {
  ZomentumPSA["ConnectWise"] = "connectwise";
  ZomentumPSA["Autotask"] = "autotask";
  ZomentumPSA["Kaseya"] = "kaseya";
  ZomentumPSA["Syncro"] = "syncro";
  ZomentumPSA["RepairShopr"] = "repairshopr";
  ZomentumPSA["Quickbooks"] = "quickbooks_online";
  ZomentumPSA["Hubspot"] = "hubspot";
  ZomentumPSA["InfusionSoft"] = "infusion_soft";
  ZomentumPSA["HaloPSA"] = "haloPSA";
  ZomentumPSA["Etilize"] = "etilize";
  ZomentumPSA["Icecat"] = "icecat";
  ZomentumPSA["Amazon"] = "amazon_business";
})(exports.ZomentumPSA || (exports.ZomentumPSA = {}));
var zomentumPSALabel = (_a = {}, _a[exports.ZomentumPSA.ConnectWise] = "ConnectWise PSA", _a[exports.ZomentumPSA.Autotask] = "Autotask", _a[exports.ZomentumPSA.Kaseya] = "Kaseya BMS", _a[exports.ZomentumPSA.Syncro] = "Syncro", _a[exports.ZomentumPSA.RepairShopr] = "RepairShopr", _a[exports.ZomentumPSA.Quickbooks] = "Quickbooks Online", _a[exports.ZomentumPSA.Hubspot] = "HubSpot", _a[exports.ZomentumPSA.InfusionSoft] = "InfusionSoft", _a[exports.ZomentumPSA.HaloPSA] = "HaloPSA", _a[exports.ZomentumPSA.Etilize] = "Etilize", _a[exports.ZomentumPSA.Icecat] = "Icecat", _a[exports.ZomentumPSA.Amazon] = "Amazon Business", _a);
var allowedPSAForCRMPushtoPSA = [exports.ZomentumPSA.ConnectWise, exports.ZomentumPSA.Kaseya, exports.ZomentumPSA.Autotask];
var ZomentumPSAsArray = [exports.ZomentumPSA.Autotask, exports.ZomentumPSA.ConnectWise, exports.ZomentumPSA.Kaseya, exports.ZomentumPSA.Syncro, exports.ZomentumPSA.HaloPSA, exports.ZomentumPSA.RepairShopr, exports.ZomentumPSA.Quickbooks, exports.ZomentumPSA.Hubspot, exports.ZomentumPSA.InfusionSoft];
exports.CBSupportedCurrency = void 0;
(function (CBSupportedCurrency) {
  CBSupportedCurrency["USD"] = "USD";
  CBSupportedCurrency["CAD"] = "CAD";
})(exports.CBSupportedCurrency || (exports.CBSupportedCurrency = {}));
exports.NumberLimit = void 0;
(function (NumberLimit) {
  NumberLimit[NumberLimit["NUMBER_MAX"] = 2147483647] = "NUMBER_MAX";
})(exports.NumberLimit || (exports.NumberLimit = {}));
exports.CommonServerStatusEnum = void 0;
(function (CommonServerStatusEnum) {
  CommonServerStatusEnum["Success"] = "success";
  CommonServerStatusEnum["Failure"] = "failure";
})(exports.CommonServerStatusEnum || (exports.CommonServerStatusEnum = {}));
exports.ShippingAddressFormItems = void 0;
(function (ShippingAddressFormItems) {
  ShippingAddressFormItems["AddressLine1"] = "address_line_1";
  ShippingAddressFormItems["AddressLine2"] = "address_line_2";
  ShippingAddressFormItems["City"] = "city";
  ShippingAddressFormItems["State"] = "state";
  ShippingAddressFormItems["Pincode"] = "pincode";
  ShippingAddressFormItems["Country"] = "country";
})(exports.ShippingAddressFormItems || (exports.ShippingAddressFormItems = {}));
exports.AppName = void 0;
(function (AppName) {
  AppName["Grow"] = "grow";
  AppName["PartnerAlign"] = "partner_align";
})(exports.AppName || (exports.AppName = {}));
exports.ResourceFileType = void 0;
(function (ResourceFileType) {
  ResourceFileType["Image"] = "image";
  ResourceFileType["Video"] = "video";
  ResourceFileType["File"] = "file";
  ResourceFileType["Spreadsheet"] = "spreadsheet";
  ResourceFileType["Document"] = "document";
  ResourceFileType["Folder"] = "folder";
  ResourceFileType["SVG"] = "svg";
  ResourceFileType["Youtube"] = "youtube";
  ResourceFileType["Pdf"] = "pdf";
  ResourceFileType["Txt"] = "txt";
  ResourceFileType["Presentation"] = "presentation";
  ResourceFileType["Csv"] = "csv";
})(exports.ResourceFileType || (exports.ResourceFileType = {}));
exports.OpportunityRevenueAndCostText = void 0;
(function (OpportunityRevenueAndCostText) {
  OpportunityRevenueAndCostText["one_time"] = "One time revenue";
  OpportunityRevenueAndCostText["weekly"] = "Weekly revenue";
  OpportunityRevenueAndCostText["quarterly"] = "Quartly revenue";
  OpportunityRevenueAndCostText["monthly"] = "Monthly revenue";
  OpportunityRevenueAndCostText["semi_annually"] = "Semi-Annually revenue";
  OpportunityRevenueAndCostText["annual"] = "Annually revenue";
  OpportunityRevenueAndCostText["semi_annually_cost"] = "Semi-Annually cost";
  OpportunityRevenueAndCostText["total_cost"] = "cost";
  OpportunityRevenueAndCostText["total_revenue"] = "revenue";
})(exports.OpportunityRevenueAndCostText || (exports.OpportunityRevenueAndCostText = {}));
exports.V2ZomentumRoutes = void 0;
(function (V2ZomentumRoutes) {
  V2ZomentumRoutes["BILLING"] = "/v2/billing";
  V2ZomentumRoutes["KASEYA"] = "/v2/kaseya";
  V2ZomentumRoutes["BULKIMPORTPAGE"] = "/v2/imports/:entity";
})(exports.V2ZomentumRoutes || (exports.V2ZomentumRoutes = {}));
exports.DistributorAvo = void 0;
(function (DistributorAvo) {
  DistributorAvo["Ingram"] = "Ingram";
  DistributorAvo["TechData"] = "Techdata";
  DistributorAvo["DAndH"] = "D&H";
  DistributorAvo["Synnex"] = "Synnex";
  DistributorAvo["DickerData"] = "Dickerdata";
  DistributorAvo["Westcoast"] = "Westcoast";
})(exports.DistributorAvo || (exports.DistributorAvo = {}));
var handleConnectedDistributors = function handleConnectedDistributors(userCompany) {
  var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
  var connectedDistributors = [];
  !!((_b = (_a = userCompany.integrations.ingram) === null || _a === void 0 ? void 0 : _a.connected_user) === null || _b === void 0 ? void 0 : _b.length) && connectedDistributors.push(exports.DistributorAvo.Ingram);
  !!((_d = (_c = userCompany.integrations.tech_data) === null || _c === void 0 ? void 0 : _c.connected_user) === null || _d === void 0 ? void 0 : _d.length) && connectedDistributors.push(exports.DistributorAvo.TechData);
  !!((_f = (_e = userCompany.integrations.d_and_h) === null || _e === void 0 ? void 0 : _e.connected_user) === null || _f === void 0 ? void 0 : _f.length) && connectedDistributors.push(exports.DistributorAvo.DAndH);
  !!((_h = (_g = userCompany.integrations.synnex) === null || _g === void 0 ? void 0 : _g.connected_user) === null || _h === void 0 ? void 0 : _h.length) && connectedDistributors.push(exports.DistributorAvo.Synnex);
  !!((_k = (_j = userCompany.integrations.dicker_data) === null || _j === void 0 ? void 0 : _j.connected_user) === null || _k === void 0 ? void 0 : _k.length) && connectedDistributors.push(exports.DistributorAvo.DickerData);
  !!((_m = (_l = userCompany.integrations.westcoast) === null || _l === void 0 ? void 0 : _l.connected_user) === null || _m === void 0 ? void 0 : _m.length) && connectedDistributors.push(exports.DistributorAvo.Westcoast);
  return connectedDistributors;
};
var handleConnectedSalesAndMarketing = function handleConnectedSalesAndMarketing(userCompany) {
  var _a, _b, _c, _d;
  var connectedSalesAndMarketing = [];
  !!((_b = (_a = userCompany.integrations.infusion_soft) === null || _a === void 0 ? void 0 : _a.connected_user) === null || _b === void 0 ? void 0 : _b.length) && connectedSalesAndMarketing.push("Infusionsoft");
  !!((_d = (_c = userCompany.integrations.hubspot) === null || _c === void 0 ? void 0 : _c.connected_user) === null || _d === void 0 ? void 0 : _d.length) && connectedSalesAndMarketing.push("Hubspot");
  return connectedSalesAndMarketing;
};
var handleConnectedPaymentGateways = function handleConnectedPaymentGateways(userCompany) {
  var _a, _b;
  var connectedPaymentGateways = [];
  !!((_b = (_a = userCompany.integrations.stripe) === null || _a === void 0 ? void 0 : _a.account_id) === null || _b === void 0 ? void 0 : _b.length) && connectedPaymentGateways.push("Stripe");
  !!userCompany.integrations.connect_booster && connectedPaymentGateways.push("Connect Booster");
  return connectedPaymentGateways;
};
var handleConnectedAccounting = function handleConnectedAccounting(userCompany) {
  var _a, _b;
  var connectedAccounting = [];
  !!((_b = (_a = userCompany.integrations.quickbooks) === null || _a === void 0 ? void 0 : _a.connected_user) === null || _b === void 0 ? void 0 : _b.length) && connectedAccounting.push("Quickbooks");
  return connectedAccounting;
};
exports.ZomentumPSAErrorTypes = void 0;
(function (ZomentumPSAErrorTypes) {
  ZomentumPSAErrorTypes["MatchingEntityExistsInIntegration"] = "matching_entities_exists_in_integration";
  ZomentumPSAErrorTypes["PermissionMissing"] = "missing_permissions";
  ZomentumPSAErrorTypes["EntityDeletedInIntegration"] = "entity_deleted_in_integration";
  ZomentumPSAErrorTypes["UnknownErrors"] = "unknown";
  ZomentumPSAErrorTypes["DependanciesNotFound"] = "dependencies_not_found";
  ZomentumPSAErrorTypes["InvalidObject"] = "invalid_object";
})(exports.ZomentumPSAErrorTypes || (exports.ZomentumPSAErrorTypes = {}));
var vendorOpportunityFieldsCommonWithGrow = ["vendor_opportunity_estimated_value", "vendor_opportunity_estimated_close_date", "vendor_opportunity_primary_client_user_id", "vendor_opportunity_linked_client_user_ids"];
var vendorClientFieldsCommonWithGrow = ["vendor_client_phone", "vendor_client_billing_address", "vendor_client_fax", "vendor_client_website", "vendor_client_linked_in_url", "vendor_client_facebook_url", "vendor_client_twitter_url"];
var vendorClientContactFieldsCommonWithGrow = ["vendor_client_user_email", "vendor_client_user_phone", "vendor_client_user_mobile", "vendor_client_user_job_title", "vendor_client_user_department"];
var CurrenciesForCustomSymbol = ["FJD"];
exports.CurrenciesSymbol = void 0;
(function (CurrenciesSymbol) {
  CurrenciesSymbol["FJD"] = "FJ$";
})(exports.CurrenciesSymbol || (exports.CurrenciesSymbol = {}));

exports.Address = Address;
exports.AlphaNumericWithDashesRegex = AlphaNumericWithDashesRegex;
exports.AlphabetsOnlyRegex = AlphabetsOnlyRegex;
exports.ArchievedWithSyncModel = ArchievedWithSyncModel;
exports.CreditCardRegex = CreditCardRegex;
exports.CurrenciesForCustomSymbol = CurrenciesForCustomSymbol;
exports.CurrencyOptions = CurrencyOptions;
exports.DefaultErrorMessage = DefaultErrorMessage;
exports.DefaultPaginationPageSize = DefaultPaginationPageSize;
exports.DefaultPartnerAlignPrivateRoute = DefaultPartnerAlignPrivateRoute;
exports.DefaultTableFilterSortSearch = DefaultTableFilterSortSearch;
exports.DefaultTimestampFormat = DefaultTimestampFormat;
exports.DefaultTimestampFormatWithTime = DefaultTimestampFormatWithTime;
exports.DeletedRecord = DeletedRecord;
exports.EmailRegex = EmailRegex;
exports.FullDateFormat = FullDateFormat;
exports.HttpErrorResponse = HttpErrorResponse;
exports.LinkHref = LinkHref;
exports.Links = Links;
exports.Name = Name;
exports.NotificationConfiguration = NotificationConfiguration;
exports.NotificationSetting = NotificationSetting;
exports.NotificationSettings = NotificationSettings;
exports.PasswordRegex = PasswordRegex;
exports.PhoneRegex = PhoneRegex;
exports.PurchaseOrderAddress = PurchaseOrderAddress;
exports.Social = Social;
exports.SubdomainRegex = SubdomainRegex;
exports.TableQueryFilter = TableQueryFilter;
exports.TimeFormat = TimeFormat;
exports.URLRegex = URLRegex;
exports.ZomentumEntity = ZomentumEntity;
exports.ZomentumEntityRequest = ZomentumEntityRequest;
exports.ZomentumLoginRoutes = ZomentumLoginRoutes;
exports.ZomentumPSAsArray = ZomentumPSAsArray;
exports.ZomentumStorageKeys = ZomentumStorageKeys;
exports.ZomentumSyncEntity = ZomentumSyncEntity;
exports.allowedPSAForCRMPushtoPSA = allowedPSAForCRMPushtoPSA;
exports.companyFormSubdomainRegex = companyFormSubdomainRegex;
exports.getDefaultGrowPrivateRoute = getDefaultGrowPrivateRoute;
exports.getRouteFromEntity = getRouteFromEntity;
exports.getZomentumEntityDisplayValue = getZomentumEntityDisplayValue;
exports.handleConnectedAccounting = handleConnectedAccounting;
exports.handleConnectedDistributors = handleConnectedDistributors;
exports.handleConnectedPaymentGateways = handleConnectedPaymentGateways;
exports.handleConnectedSalesAndMarketing = handleConnectedSalesAndMarketing;
exports.vendorClientContactFieldsCommonWithGrow = vendorClientContactFieldsCommonWithGrow;
exports.vendorClientFieldsCommonWithGrow = vendorClientFieldsCommonWithGrow;
exports.vendorOpportunityFieldsCommonWithGrow = vendorOpportunityFieldsCommonWithGrow;
exports.zomentumPSALabel = zomentumPSALabel;
