'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var tslib_es6 = require('../node_modules/tslib/tslib.es6.mjs.js');
var common = require('../common.js');

var _a, _b, _c, _d, _e, _f, _g, _h;
var ProductCount = /** @class */function () {
  function ProductCount() {
    this.bundle = 0;
    this.hardware = 0;
    this.service = 0;
    this.software = 0;
    this.all = 0;
  }
  return ProductCount;
}();
exports.ProductType = void 0;
(function (ProductType) {
  ProductType["Hardware"] = "hardware";
  ProductType["Software"] = "software";
  ProductType["Service"] = "service";
  ProductType["Bundle"] = "bundle";
  ProductType["Labor"] = "labor";
  ProductType["Other"] = "other";
})(exports.ProductType || (exports.ProductType = {}));
exports.EditOrCloneProductDrawerMode = void 0;
(function (EditOrCloneProductDrawerMode) {
  EditOrCloneProductDrawerMode["EDIT"] = "edit";
  EditOrCloneProductDrawerMode["CLONE"] = "clone";
})(exports.EditOrCloneProductDrawerMode || (exports.EditOrCloneProductDrawerMode = {}));
var ProductTypes = [exports.ProductType.Hardware, exports.ProductType.Software, exports.ProductType.Service, exports.ProductType.Bundle, exports.ProductType.Labor];
var ProductTypeObj = (_a = {}, _a[exports.ProductType.Hardware] = "hardware", _a[exports.ProductType.Software] = "software", _a[exports.ProductType.Service] = "service", _a[exports.ProductType.Bundle] = "bundle", _a[exports.ProductType.Labor] = "labor", _a[exports.ProductType.Other] = "other", _a);
var ProductTypeNames = (_b = {}, _b[exports.ProductType.Hardware] = "Hardware", _b[exports.ProductType.Software] = "Software", _b[exports.ProductType.Service] = "Service", _b[exports.ProductType.Bundle] = "Bundle", _b[exports.ProductType.Labor] = "Labor", _b[exports.ProductType.Other] = "Other", _b);
exports.ProductBillingType = void 0;
(function (ProductBillingType) {
  ProductBillingType["OneTime"] = "one_time";
  ProductBillingType["Weekly"] = "weekly";
  ProductBillingType["Monthly"] = "monthly";
  ProductBillingType["Annual"] = "annual";
  ProductBillingType["Quarterly"] = "quarterly";
  ProductBillingType["SemiAnnually"] = "semi_annually";
})(exports.ProductBillingType || (exports.ProductBillingType = {}));
var ProductBillingTypeDisplayText = (_c = {}, _c[exports.ProductBillingType.OneTime] = "One Time", _c[exports.ProductBillingType.Weekly] = "Weekly", _c[exports.ProductBillingType.Monthly] = "Monthly", _c[exports.ProductBillingType.Annual] = "Annually", _c[exports.ProductBillingType.Quarterly] = "Quarterly", _c[exports.ProductBillingType.SemiAnnually] = "Semi Annually", _c);
var billingPeriodUnitText = (_d = {}, _d[exports.ProductBillingType.Weekly] = "week", _d[exports.ProductBillingType.Monthly] = "month", _d[exports.ProductBillingType.Quarterly] = "quarter", _d[exports.ProductBillingType.SemiAnnually] = "semi-annually", _d[exports.ProductBillingType.Annual] = "year", _d[exports.ProductBillingType.OneTime] = "(one time)", _d);
exports.ProductTaxType = void 0;
(function (ProductTaxType) {
  ProductTaxType["default"] = "default";
  ProductTaxType["disabled"] = "disabled";
})(exports.ProductTaxType || (exports.ProductTaxType = {}));
var productTaxTypeOptions = (_e = {}, _e[exports.ProductTaxType["default"]] = "Taxable", _e[exports.ProductTaxType.disabled] = "Non Taxable", _e);
var productBillingTypeOptions = (_f = {}, _f[exports.ProductBillingType.OneTime] = "One Time", _f[exports.ProductBillingType.Weekly] = "Weekly", _f[exports.ProductBillingType.Monthly] = "Monthly", _f[exports.ProductBillingType.Annual] = "Annually", _f[exports.ProductBillingType.Quarterly] = "Quarterly", _f[exports.ProductBillingType.SemiAnnually] = "Semi-Annually", _f);
var Product = /** @class */function (_super) {
  tslib_es6.__extends(Product, _super);
  function Product() {
    var _this = _super !== null && _super.apply(this, arguments) || this;
    _this.bundled_items = new Array();
    _this.created = new Date();
    _this.description = "";
    _this.id = "";
    _this.name = "";
    _this.partner_id = "";
    _this.pricing = new Array();
    _this.product_category = "";
    _this.type = null;
    _this.logo = "";
    _this.item_images = new Array();
    _this.updated = new Date();
    _this.vendor_id = "";
    _this.is_duplicate_manufacturer_part_no = false;
    _this.mandatory_custom_field_check_require = false;
    _this.selected_pricing_distributor = null;
    _this.vendor_company_id = null;
    _this.vendor_name = null;
    _this.vendor_sku_no = null;
    return _this;
  }
  return Product;
}(common.ArchievedWithSyncModel);
var ProductsRequest = /** @class */function () {
  function ProductsRequest(filters, keywords, item_source, cursor) {
    this.item_source = null;
    this.cursor = null;
    this.filters = filters;
    this.keywords = keywords;
    this.item_source = item_source;
    this.cursor = cursor;
  }
  return ProductsRequest;
}();
var ProductTemplates = /** @class */function () {
  function ProductTemplates(template_ids, item_id) {
    this.template_ids = template_ids;
    this.item_id = item_id;
  }
  return ProductTemplates;
}();
var ProductsResponse = /** @class */function () {
  function ProductsResponse() {
    this.base = "";
    this.filtered_count_map = new ProductCount();
    this.data = new Array();
    this.links = new common.Links();
    this.next = "";
  }
  return ProductsResponse;
}();
var QuoteBlockProductPricingRequest = /** @class */function () {
  function QuoteBlockProductPricingRequest() {
    this.product_id = "";
    this.pricing_id = "";
    this.sku = "";
    this.mfg_no = "";
  }
  return QuoteBlockProductPricingRequest;
}();
var ProductPricingPlan = /** @class */function () {
  function ProductPricingPlan() {
    this.created = new Date();
    this.id = null;
    this.item_id = null;
    this.session_id = null;
    this.name = "";
    this.description = "";
    this.billing_period = exports.ProductBillingType.OneTime;
    this.cost_price = 0;
    this.sell_price = 0;
    this.setup_price = 0;
    this.updated = new Date();
    this.distributor_pricing_details = null;
    this.markup = 0;
    this.margin = 0;
    this.currency_exchange_details = null;
  }
  ProductPricingPlan.fromSKUPricingPlan = function (skuPricing) {
    var _a, _b;
    var pricingPlan = new ProductPricingPlan();
    pricingPlan.name = skuPricing.name;
    pricingPlan.description = skuPricing.description;
    pricingPlan.billing_period = skuPricing.billing_period;
    pricingPlan.cost_price = skuPricing.cost_price;
    pricingPlan.sell_price = skuPricing.sell_price;
    pricingPlan.markup = (_a = skuPricing.markup) !== null && _a !== void 0 ? _a : 0;
    pricingPlan.margin = (_b = skuPricing.margin) !== null && _b !== void 0 ? _b : 0;
    pricingPlan.setup_price = skuPricing.setup_price;
    pricingPlan.vendor_sku_no = skuPricing.vendor_sku_no;
    return pricingPlan;
  };
  return ProductPricingPlan;
}();
exports.Distributor = void 0;
(function (Distributor) {
  Distributor["Amazon"] = "amazon_business";
  Distributor["Ingram"] = "ingram";
  Distributor["TechData"] = "tech_data";
  Distributor["DAndH"] = "d_and_h";
  Distributor["Synnex"] = "synnex";
  Distributor["DickerData"] = "dicker_data";
  Distributor["Westcoast"] = "westcoast";
  Distributor["CustomDistributor"] = "custom_distributor";
})(exports.Distributor || (exports.Distributor = {}));
var distributors = [exports.Distributor.Ingram, exports.Distributor.TechData, exports.Distributor.DAndH, exports.Distributor.Synnex, exports.Distributor.DickerData, exports.Distributor.Westcoast, exports.Distributor.CustomDistributor];
exports.PricingOptions = void 0;
(function (PricingOptions) {
  PricingOptions["Catalog"] = "catalog";
  PricingOptions["Custom"] = "custom";
})(exports.PricingOptions || (exports.PricingOptions = {}));
var distributorLabel = (_g = {}, _g[exports.Distributor.Ingram] = "Ingram", _g[exports.Distributor.TechData] = "Tech Data", _g[exports.Distributor.DAndH] = "D&H", _g[exports.Distributor.Synnex] = "Synnex", _g[exports.Distributor.DickerData] = "Dicker Data", _g[exports.Distributor.Westcoast] = "Westcoast", _g[exports.Distributor.CustomDistributor] = "Custom Distributor", _g[exports.Distributor.Amazon] = "Amazon Business", _g);
var ratailPriceLabel = (_h = {}, _h[exports.Distributor.Ingram] = "Retail Price", _h[exports.Distributor.TechData] = "Retail Price", _h[exports.Distributor.DAndH] = "Retail Price", _h[exports.Distributor.Synnex] = "Retail Price", _h[exports.Distributor.DickerData] = "Retail Price", _h[exports.Distributor.Westcoast] = "Retail Price", _h[exports.Distributor.CustomDistributor] = "Retail Price", _h[exports.Distributor.Amazon] = "Amazon Business", _h);
var noOfDistributorsIntegrated = function noOfDistributorsIntegrated(integrations) {
  var _a;
  var count = 0;
  count += integrations.ingram !== null ? 1 : 0;
  count += integrations.tech_data !== null ? 1 : 0;
  count += integrations.d_and_h !== null ? 1 : 0;
  count += integrations.synnex !== null ? 1 : 0;
  count += integrations.dicker_data !== null ? 1 : 0;
  count += integrations.westcoast !== null ? 1 : 0;
  count += Array.isArray(integrations.custom_distributor) ? (_a = integrations === null || integrations === void 0 ? void 0 : integrations.custom_distributor) === null || _a === void 0 ? void 0 : _a.length : 0;
  return count;
};
var displayPricingName = function displayPricingName(pricing) {
  var _a, _b;
  var distributor = (_a = pricing === null || pricing === void 0 ? void 0 : pricing.distributor_pricing_details) === null || _a === void 0 ? void 0 : _a.distributor;
  if (distributor === exports.Distributor.CustomDistributor) {
    return (pricing === null || pricing === void 0 ? void 0 : pricing.vendor_name) || (pricing === null || pricing === void 0 ? void 0 : pricing.name) || "";
  }
  var name = ((_b = pricing === null || pricing === void 0 ? void 0 : pricing.distributor_pricing_details) === null || _b === void 0 ? void 0 : _b.distributor) ? distributorLabel[pricing.distributor_pricing_details.distributor] || pricing.distributor_pricing_details.distributor : (pricing === null || pricing === void 0 ? void 0 : pricing.name) || "";
  return name;
};
var DistributorPricingDetails = /** @class */function () {
  function DistributorPricingDetails() {
    this.distributor = exports.Distributor.Ingram;
    this.total_availability = 0;
    this.total_on_order = 0;
    this.billing_period = null;
    this.sell_price = 0;
    this.setup_price = 0;
    this.cost_price = 0;
    this.branch_wise_details = [];
    this.vendor_name = "";
    this.retail_price = null;
  }
  return DistributorPricingDetails;
}();
var DistributorBranchDetails = /** @class */function () {
  function DistributorBranchDetails() {
    this.branch_name = "";
    this.availability = 0;
    this.on_order = 0;
    this.eta_date = new Date();
  }
  return DistributorBranchDetails;
}();
var AddOrEditProductRequest = /** @class */function () {
  function AddOrEditProductRequest() {
    this.bundled_items = new Array();
    this.description = "";
    this.name = "";
    this.pricing = new Array();
    this.type = null;
    this.item_images = new Array();
    this.vendor_id = "";
    this.should_update_templates = false;
    this.mandatory_custom_field_check_require = false;
    this.selected_pricing_distributor = null;
  }
  return AddOrEditProductRequest;
}();
var ProductFormValues = /** @class */function (_super) {
  tslib_es6.__extends(ProductFormValues, _super);
  function ProductFormValues() {
    var _this = _super !== null && _super.apply(this, arguments) || this;
    _this.custom_field_form_values = {};
    return _this;
  }
  return ProductFormValues;
}(Product);
exports.ProductSource = void 0;
(function (ProductSource) {
  ProductSource["All"] = "self,etilize";
  ProductSource["Self"] = "self";
  ProductSource["Etilize"] = "etilize";
  ProductSource["DellQuote"] = "dell_quote";
  ProductSource["ZomentumMarketplace"] = "marketplace";
  ProductSource["Icecat"] = "icecat";
  ProductSource["Amazon"] = "amazon_business";
})(exports.ProductSource || (exports.ProductSource = {}));
exports.SelfCatalogFilterKeys = void 0;
(function (SelfCatalogFilterKeys) {
  SelfCatalogFilterKeys["FilterByProductType"] = "filter_by_item_types";
  SelfCatalogFilterKeys["FilterByCategory"] = "filter_by_categories";
  SelfCatalogFilterKeys["FilterByManufacturers"] = "filter_by_manufacturers";
  SelfCatalogFilterKeys["FilterByVendors"] = "filter_by_vendors";
  SelfCatalogFilterKeys["FilterBySyncSource"] = "filter_by_sync_source";
  SelfCatalogFilterKeys["FilterBySupplierIds"] = "filter_by_supplier_ids";
})(exports.SelfCatalogFilterKeys || (exports.SelfCatalogFilterKeys = {}));
var SelfCatalogFilters = /** @class */function () {
  function SelfCatalogFilters() {}
  return SelfCatalogFilters;
}();
// Product search Request contract
var ETILIZE_PAGE_SIZE = 20;
var ProductSearchRequest = /** @class */function () {
  function ProductSearchRequest() {
    this.q = null;
    this.exclude_drop_ship_wh = false;
    this.filter_by_item_sources = [];
    this.cursor = null;
    this.filter_by_categories = [];
    this.filter_by_manufacturers = [];
    this.filter_by_features = [];
    this.filter_by_connected_distributors = false;
    this.exclude_out_of_stock_products = false;
    this.filter_by_item_types = [];
    this.fetch_realtime_pricing = false;
    this.filter_by_sub_categories = [];
  }
  ProductSearchRequest.constructSearchRequest = function (q, source, cursor, fetchRealtimePricing, filterByConnectedDistributors, excludeOutOfStock, excludeDropShipWH, manufacturerFilters, categoriesFilters, featureFilters, itemTypeFilters, selfCatalogFilters, is_request_for_lazy_load, sort_by, amazonSubCategory, amazonRefineMentsFilters) {
    var searchRequest = new ProductSearchRequest();
    var sourceFilters = [source.toString()];
    searchRequest.q = q && q.length > 0 ? q : null;
    searchRequest.filter_by_item_sources = sourceFilters;
    searchRequest.cursor = cursor;
    searchRequest.filter_by_manufacturers = manufacturerFilters !== null && manufacturerFilters !== void 0 ? manufacturerFilters : [];
    searchRequest.filter_by_categories = categoriesFilters !== null && categoriesFilters !== void 0 ? categoriesFilters : [];
    searchRequest.filter_by_features = featureFilters !== null && featureFilters !== void 0 ? featureFilters : [];
    searchRequest.filter_by_connected_distributors = !!filterByConnectedDistributors;
    searchRequest.exclude_out_of_stock_products = !!excludeOutOfStock;
    searchRequest.exclude_drop_ship_wh = !!excludeDropShipWH;
    searchRequest.filter_by_item_types = itemTypeFilters !== null && itemTypeFilters !== void 0 ? itemTypeFilters : [];
    searchRequest.fetch_realtime_pricing = fetchRealtimePricing;
    searchRequest.self_catalog_filters = selfCatalogFilters;
    searchRequest.is_request_for_lazy_load = is_request_for_lazy_load;
    searchRequest.sort_by = sort_by;
    searchRequest.filter_by_sub_categories = amazonSubCategory !== null && amazonSubCategory !== void 0 ? amazonSubCategory : [];
    searchRequest.amazon_refinements = amazonRefineMentsFilters;
    return searchRequest;
  };
  return ProductSearchRequest;
}();
exports.AmazonCatalogFilterKeys = void 0;
(function (AmazonCatalogFilterKeys) {
  AmazonCatalogFilterKeys["FilterByProductCategory"] = "filter_by_product_category";
  AmazonCatalogFilterKeys["FilterBySubCategory"] = "filter_by_sub_categories";
  AmazonCatalogFilterKeys["FilterByDeliveryDay"] = "filter_by_delivery_day";
  AmazonCatalogFilterKeys["FilterByAvailability"] = "filter_by_availability";
})(exports.AmazonCatalogFilterKeys || (exports.AmazonCatalogFilterKeys = {}));
var AmazonRefinementsFilters = /** @class */function () {
  function AmazonRefinementsFilters() {}
  return AmazonRefinementsFilters;
}();
var PricingSearchRequest = /** @class */function () {
  function PricingSearchRequest() {
    this.etilize_products_details = null;
    this.icecat_products_details = null;
    this.exclude_drop_ship_wh = false;
    this.q = null;
  }
  PricingSearchRequest.constructSearchRequest = function (etilize_products_details, icecat_products_details, exclude_drop_ship_wh, q) {
    var searchRequest = new PricingSearchRequest();
    searchRequest.etilize_products_details = etilize_products_details;
    searchRequest.icecat_products_details = icecat_products_details;
    searchRequest.exclude_drop_ship_wh = exclude_drop_ship_wh;
    searchRequest.q = (q === null || q === void 0 ? void 0 : q.length) ? q : null;
    return searchRequest;
  };
  return PricingSearchRequest;
}();
var ItemSearchCursor = /** @class */function () {
  function ItemSearchCursor() {
    this.current_source = exports.ProductSource.Self;
    this.current_page_no = 1;
    this.page_size = 20;
  }
  return ItemSearchCursor;
}();
var EtilizeProductDetails = /** @class */function () {
  function EtilizeProductDetails() {
    this.id = null;
    this.manufacturer_part_no = "";
  }
  return EtilizeProductDetails;
}();
var IcecatProductDetails = /** @class */function () {
  function IcecatProductDetails() {
    this.id = null;
    this.manufacturer_part_no = "";
  }
  return IcecatProductDetails;
}();
var FeatureFilter = /** @class */function () {
  function FeatureFilter() {
    this.id = "";
    this.values = [];
  }
  return FeatureFilter;
}();
var AmazonRefineMents = /** @class */function () {
  function AmazonRefineMents() {
    this.availability_options = [];
    this.delivery_day_options = [];
    this.eligible_for_free_shipping_options = [];
    this.prime_eligible = [];
  }
  return AmazonRefineMents;
}();
var ProductMetaInfo = /** @class */function () {
  function ProductMetaInfo() {
    this.manufacturers = new Array();
    this.categories = new Array();
    this.features = new Array();
    this.sub_categories = new Array();
    this.amazon_refinements = new AmazonRefineMents();
  }
  return ProductMetaInfo;
}();
var ProductSearchResponse = /** @class */function () {
  function ProductSearchResponse() {
    this.base = "";
    this.filtered_count_map = new ProductCount();
    this.data = new Array();
    this.links = new common.Links();
    this.next = new ItemSearchCursor();
    this.meta_info = new ProductMetaInfo();
  }
  return ProductSearchResponse;
}();
var SelfProductPricingSearchData = /** @class */function () {
  function SelfProductPricingSearchData() {
    this.item_id = "";
    this.pricing = new Array();
  }
  return SelfProductPricingSearchData;
}();
var SelfProductPricingSearchResponse = /** @class */function () {
  function SelfProductPricingSearchResponse() {
    this.data = new Array();
  }
  return SelfProductPricingSearchResponse;
}();
var ProductKeyValuePair = /** @class */function () {
  function ProductKeyValuePair() {
    this.label = "";
    this.value = "";
    this.count = 0;
  }
  return ProductKeyValuePair;
}();
var ProductFeature = /** @class */function () {
  function ProductFeature() {
    this.label = "";
    this.value = "";
    this.count = 0;
    this.children = new Array();
  }
  return ProductFeature;
}();
var getSelectableProductBulkUpdateFields = function getSelectableProductBulkUpdateFields(customFields) {
  var _a;
  return [{
    display_value: "Title",
    id_value: "name"
  }, {
    display_value: "Description",
    id_value: "description"
  }, {
    display_value: "Category",
    id_value: "product_category"
  }, {
    display_value: "Manufacturer",
    id_value: "manufacturer"
  }, {
    display_value: "Tax Category",
    id_value: "tax_category_id"
  }, {
    display_value: "UOM",
    id_value: "unit_of_measure"
  }].concat(tslib_es6.__spreadArray([], (_a = customFields === null || customFields === void 0 ? void 0 : customFields.filter(function (customField) {
    return customField.is_value_editable_from_ui !== false && (!customField.system_field_id || customField.system_field_id.length === 0);
  })) === null || _a === void 0 ? void 0 : _a.map(function (customField) {
    return {
      display_value: customField.display_name,
      id_value: "custom_fields.".concat(customField.id)
    };
  }), true));
};
exports.UOM = void 0;
(function (UOM) {
  UOM["CASE"] = "Case";
  UOM["EACH"] = "Each";
  UOM["PerHour"] = "Per Hour";
  UOM["PerMonth"] = "Per Month";
})(exports.UOM || (exports.UOM = {}));
exports.BulkUpdateProductFilterDisplayValues = void 0;
(function (BulkUpdateProductFilterDisplayValues) {
  BulkUpdateProductFilterDisplayValues["TITLE"] = "Title";
  // Add more filters here - For future reference
})(exports.BulkUpdateProductFilterDisplayValues || (exports.BulkUpdateProductFilterDisplayValues = {}));
var MergeDuplicateProductsRequest = /** @class */function () {
  function MergeDuplicateProductsRequest() {
    this.primary_item_id = "";
  }
  MergeDuplicateProductsRequest.constructMergeDuplicateProductsRequest = function (primaryProductId) {
    var mergeDuplicateProductsRequest = new MergeDuplicateProductsRequest();
    mergeDuplicateProductsRequest.primary_item_id = primaryProductId;
    return mergeDuplicateProductsRequest;
  };
  return MergeDuplicateProductsRequest;
}();
exports.SortOptions = void 0;
(function (SortOptions) {
  SortOptions["CostPriceHighToLow"] = "cost_price_high_to_low";
  SortOptions["CostPriceLowToHigh"] = "cost_price_low_to_high";
  SortOptions["SellPriceHighToLow"] = "sell_price_high_to_low";
  SortOptions["SellPriceLowToHigh"] = "sell_price_low_to_high";
  SortOptions["NameAToZ"] = "name_a_to_z";
  SortOptions["NameZToA"] = "name_z_to_a";
})(exports.SortOptions || (exports.SortOptions = {}));
exports.ProductMiscEnums = void 0;
(function (ProductMiscEnums) {
  ProductMiscEnums["DefaultPricing"] = "Default Pricing";
})(exports.ProductMiscEnums || (exports.ProductMiscEnums = {}));
exports.FilterOptions = void 0;
(function (FilterOptions) {
  FilterOptions["Manufacturers"] = "Manufacturers";
  FilterOptions["Categories"] = "Categories";
  FilterOptions["Features"] = "Features";
  FilterOptions["SelfProductType"] = "filter_by_item_types";
  FilterOptions["SelfCategory"] = "filter_by_categories";
  FilterOptions["SelfManufacturers"] = "filter_by_manufacturers";
  FilterOptions["SelfVendors"] = "filter_by_vendors";
  FilterOptions["SelfSyncSource"] = "filter_by_sync_source";
})(exports.FilterOptions || (exports.FilterOptions = {}));
exports.FilterTagType = void 0;
(function (FilterTagType) {
  FilterTagType["Manufacturer"] = "Manufacturer";
  FilterTagType["Category"] = "Category";
  FilterTagType["Feat"] = "Feat";
  FilterTagType["SelfProductType"] = "filter_by_item_types";
  FilterTagType["SelfCategory"] = "filter_by_categories";
  FilterTagType["SelfManufacturer"] = "filter_by_manufacturers";
  FilterTagType["SelfVendor"] = "filter_by_vendors";
  FilterTagType["SelfSupplier"] = "filter_by_supplier_ids";
  FilterTagType["SelfSyncSource"] = "filter_by_sync_source";
  FilterTagType["AmazonProductCategory"] = "filter_by_amazon_product_category";
  FilterTagType["AmazonProductSubCategory"] = "filter_by_amazon_product_sub_category";
  FilterTagType["AmazonProductDeliveryDay"] = "filter_by_amazon_product_deliveryDay";
  FilterTagType["AmazonProductAvailability"] = "filter_by_amazon_product_availabitity";
})(exports.FilterTagType || (exports.FilterTagType = {}));
var FilterTagData = /** @class */function () {
  function FilterTagData() {
    this.type = exports.FilterTagType.Manufacturer;
    this.id = "";
    this.name = "";
  }
  return FilterTagData;
}();
var SortingOptions = [{
  name: "Sell price: Low to High",
  value: exports.SortOptions.SellPriceLowToHigh
}, {
  name: "Sell price: High to Low",
  value: exports.SortOptions.SellPriceHighToLow
}, {
  name: "Cost price: Low to High",
  value: exports.SortOptions.CostPriceLowToHigh
}, {
  name: "Cost price: High to Low",
  value: exports.SortOptions.CostPriceHighToLow
}, {
  name: "Name: A to Z",
  value: exports.SortOptions.NameAToZ
}, {
  name: "Name: Z to A",
  value: exports.SortOptions.NameZToA
}];

exports.AddOrEditProductRequest = AddOrEditProductRequest;
exports.AmazonRefineMents = AmazonRefineMents;
exports.AmazonRefinementsFilters = AmazonRefinementsFilters;
exports.DistributorBranchDetails = DistributorBranchDetails;
exports.DistributorPricingDetails = DistributorPricingDetails;
exports.ETILIZE_PAGE_SIZE = ETILIZE_PAGE_SIZE;
exports.EtilizeProductDetails = EtilizeProductDetails;
exports.FeatureFilter = FeatureFilter;
exports.FilterTagData = FilterTagData;
exports.IcecatProductDetails = IcecatProductDetails;
exports.ItemSearchCursor = ItemSearchCursor;
exports.MergeDuplicateProductsRequest = MergeDuplicateProductsRequest;
exports.PricingSearchRequest = PricingSearchRequest;
exports.Product = Product;
exports.ProductBillingTypeDisplayText = ProductBillingTypeDisplayText;
exports.ProductCount = ProductCount;
exports.ProductFeature = ProductFeature;
exports.ProductFormValues = ProductFormValues;
exports.ProductKeyValuePair = ProductKeyValuePair;
exports.ProductMetaInfo = ProductMetaInfo;
exports.ProductPricingPlan = ProductPricingPlan;
exports.ProductSearchRequest = ProductSearchRequest;
exports.ProductSearchResponse = ProductSearchResponse;
exports.ProductTemplates = ProductTemplates;
exports.ProductTypeNames = ProductTypeNames;
exports.ProductTypeObj = ProductTypeObj;
exports.ProductTypes = ProductTypes;
exports.ProductsRequest = ProductsRequest;
exports.ProductsResponse = ProductsResponse;
exports.QuoteBlockProductPricingRequest = QuoteBlockProductPricingRequest;
exports.SelfCatalogFilters = SelfCatalogFilters;
exports.SelfProductPricingSearchData = SelfProductPricingSearchData;
exports.SelfProductPricingSearchResponse = SelfProductPricingSearchResponse;
exports.SortingOptions = SortingOptions;
exports.billingPeriodUnitText = billingPeriodUnitText;
exports.displayPricingName = displayPricingName;
exports.distributorLabel = distributorLabel;
exports.distributors = distributors;
exports.getSelectableProductBulkUpdateFields = getSelectableProductBulkUpdateFields;
exports.noOfDistributorsIntegrated = noOfDistributorsIntegrated;
exports.productBillingTypeOptions = productBillingTypeOptions;
exports.productTaxTypeOptions = productTaxTypeOptions;
exports.ratailPriceLabel = ratailPriceLabel;
